import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  AlertTitle,
} from '@mui/material';

/**
 * iconMapping in MuiOverrides caused issues with deepMerging themes so we use our own Alert component instead.
 */
export interface AlertProps extends Omit<MuiAlertProps, 'title' | 'children'> {
  description?: React.ReactNode;
  title: React.ReactNode;
}
const Alert = ({ title, description, sx, ...rest }: AlertProps) => {
  return (
    <MuiAlert {...rest}>
      <AlertTitle
        sx={{
          mb: 0,
          ...sx,
        }}
      >
        {title}
      </AlertTitle>
      {description}
    </MuiAlert>
  );
};

export default Alert;
