import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { SiteDetailResponse } from 'types/site.types';

export const useSiteDetail = (
  id: string,
  options?: UseCommonQueryOptions<
    SiteDetailResponse,
    ReturnType<typeof QueryKeys.sites.detail>
  >,
) => {
  const { data, isLoading, isError } = useQuery(
    QueryKeys.sites.detail(id),
    () => apiService.getSiteDetail(id),
    {
      ...options,
    },
  );
  return {
    data,
    isLoading,
    isError,
  };
};
