import { Components, Theme } from '@mui/material';

export const MuiSvgIcon: Components<Theme>['MuiSvgIcon'] = {
  defaultProps: {
    fontSize: 'small',
  },
  styleOverrides: {
    fontSizeSmall: {
      fontSize: 24,
    },
  },
};
