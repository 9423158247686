import { notEmpty } from 'utils/typescript.utils';

import { AdviceParams, DiagnosticParams } from 'types/advice.types';
import { BIEmbed } from 'types/bi.types';

export enum Keys {
  Account = 'account',
  Settings = 'settings',
  Dashboard = 'dashboard',
  Login = 'login',
  Advice = 'advice',
  Diagnostic = 'diagnostic',
  Overview = 'overview',
  Detail = 'detail',
  BiEmbedUrl = 'biEmbedUrl',
  Sites = 'sites',
}

export const QueryKeys = {
  account: {
    currentUser: () => [Keys.Account, Keys.Login],
    settings: () => [Keys.Account, Keys.Settings],
  },
  advice: {
    overview: (params?: AdviceParams) =>
      [Keys.Advice, Keys.Overview, params].filter(notEmpty),
    detail: (id: string) => [Keys.Advice, Keys.Detail, id],
  },
  diagnostic: {
    overview: (params?: DiagnosticParams) =>
      [Keys.Diagnostic, Keys.Overview, params].filter(notEmpty),
    detail: (id: string) => [Keys.Diagnostic, Keys.Detail, id],
  },
  dashboard: {
    overview: () => [Keys.Dashboard, Keys.Overview],
  },
  bi: {
    embedUrl: (params?: BIEmbed) => [Keys.BiEmbedUrl, params].filter(notEmpty),
  },
  sites: {
    detail: (id: string) => [Keys.Sites, Keys.Detail, id],
  },
};
