import { Components, Theme } from '@mui/material';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
    @font-face {
      font-family: 'Buenos Aires';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: local('Buenos Aires'), local('BuenosAires-Regular'), url('/fonts/BuenosAires-Regular.woff2') format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }
    @font-face {
      font-family: 'Buenos Aires';
      font-style: normal;
      font-display: swap;
      font-weight: 300;
      src: local('Buenos Aires'), local('BuenosAires-Light'), url('/fonts/BuenosAires-Light.woff2') format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }
    html {
      font-size: 18px;
      height: 100%;
    }
    body {
      font-size: 18px;
      font-family: "Buenos Aires", sans-serif;
      height: 100%;
      background-color: #FAFBFC;
    }
    #root {
      height: 100%;
    }
  `,
};
