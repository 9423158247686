import { Auth } from '@aws-amplify/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { CognitoError } from 'types/auth.types';

export const useDeleteLogin = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation<unknown, CognitoError, void>(
    () => Auth.signOut(),
    {
      onSettled: () => {
        queryClient.removeQueries(QueryKeys.account.currentUser());
        queryClient.removeQueries(QueryKeys.dashboard.overview());
      },
    },
  );
  return {
    signOut: mutate,
    isLoading,
    error,
  };
};
