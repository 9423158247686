import { styled } from '@mui/material';
import { Suspense, SVGProps } from 'react';

import DefaultIconLookup from './icons';

export type IconNames = keyof typeof DefaultIconLookup;

interface Props extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  name: IconNames;
}

const Icon = ({ name, ...rest }: Props) => {
  const SvgIcon = DefaultIconLookup[name];
  return SvgIcon ? (
    <Suspense fallback={null}>
      <SvgIcon {...rest} />
    </Suspense>
  ) : null;
};

export default styled(Icon)({});
