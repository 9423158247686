import { Box, BoxProps } from '@mui/material';
import { ReactNode, useContext } from 'react';

import { tabsContext } from './TabsContext';

export interface TabPanelProps extends BoxProps {
  value: string;
  tab: ReactNode;
}

const TabPanel = ({ value, children, tab: _tab, ...rest }: TabPanelProps) => {
  const { activeId, hasMounted } = useContext(tabsContext);
  /* hasMounted becuase we don't want all the tabpanels to mount at once 
  --> for example a useEffect hook in a tabpanel would cause them to fire all
  */
  if (!hasMounted.includes(value)) return null;

  return (
    <Box
      {...rest}
      display={value === activeId ? 'flex' : 'none'}
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default TabPanel;
