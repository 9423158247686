import React from 'react';

import { Routes } from 'pages/routes.constants';

export default {
  Component: React.lazy(
    () => import('./Detail' /* webpackChunkName: "SitePassportsDetail", */),
  ),
  path: Routes.SitePassportDetail,
  authenticated: true,
};
