import { useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Diagnostic, DiagnosticParams } from 'types/advice.types';
import { CollectionResponse } from 'types/response.types';

export const useDiagnostics = (
  params?: DiagnosticParams,
  options?: UseCommonQueryOptions<
    CollectionResponse<Diagnostic>,
    ReturnType<typeof QueryKeys.diagnostic.overview>
  >,
) => {
  const {
    data: collection,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    QueryKeys.diagnostic.overview(params),
    () => apiService.getDiagnostics(params),
    {
      staleTime: StaleTime.FIVE_MIN,
      cacheTime: StaleTime.FIVE_MIN,
      refetchOnWindowFocus: true,
      select: (collection) => {
        const data = collection?.data.sort((a, b) => {
          return +parseISO(a.createdAt) < +parseISO(b.createdAt) ? -1 : 1;
        });
        return {
          ...collection,
          data,
        };
      },
      ...options,
    },
  );

  return {
    data: collection?.data ?? [],
    isLoading,
    isError,
    refetch,
  };
};
