import { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    variant: 'filled',
    MenuProps: {
      sx: { mt: 1 },
      elevation: 2,
    },
  },
  styleOverrides: {
    select: {
      minWidth: 90,
      fontWeight: 500,
    },
    nativeInput: {
      fontWeight: 500,
    },
    filled: {
      fontWeight: 500,
    },
  },
};
