import storage from 'redux-persist/lib/storage';

import config from 'config';

import { generateStore } from 'services/redux';

import combinedReducers from './reducers';

const store = generateStore(combinedReducers, {
  initApp: async (_store) => {
    // Initialize your application/get whatever data you need before the application can be used
  },
  enableDevTools: Boolean(config.ENABLE_DEV_TOOLS),
  persistConfig: {
    key: 'root',
    keyPrefix: '',
    storage,
    version: 1,
    debug: Boolean(config.ENABLE_DEV_TOOLS),
    whitelist: ['intl'],
    // add subsets to persist nested data
    transforms: [],
  },
});

export default store;
