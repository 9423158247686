import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import {
  Children,
  ComponentProps,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react';

import { notEmpty } from 'utils/typescript.utils';

import TabPanel from './TabPanel';
import { tabsContext } from './TabsContext';

export type Tab = {
  label: ReactNode;
  value: string;
  panel?: ComponentProps<typeof TabPanel>;
};

interface Props {
  initialActive?: string;
  onTabChange?: (tabId: string) => void;
  children: ReactNode;
}

const { Provider } = tabsContext;

const tabPanelsToTabs = (children: ReactNode): Tab[] => {
  const tabs = Children.map(children, (child) => {
    if (isValidElement(child) && child.type === TabPanel) {
      const tabPane = child as ReactElement<ComponentProps<typeof TabPanel>>;
      return {
        label: tabPane.props.tab,
        value: tabPane.props.value,
        panel: tabPane.props,
      };
    }
    return null;
  })?.filter(notEmpty);
  return tabs ?? [];
};

const Tabs = ({
  children,
  initialActive = '',
  onTabChange = () => {},
}: Props) => {
  const tabs = tabPanelsToTabs(children);
  const initalActiveTab = (initialActive || tabs[0]?.value) ?? '';
  const [activeId, setActiveId] = useState(initalActiveTab);
  const [hasMounted, setHasMounted] = useState<string[]>([initalActiveTab]);

  const handleChange = (id: string) => {
    setActiveId(id);
    onTabChange(id);
    setHasMounted(Array.from(new Set([...hasMounted, id])));
  };

  return (
    <Provider
      value={{
        activeId,
        handleChange,
        hasMounted,
      }}
    >
      <MuiTabs
        onChange={(_, value) => handleChange(value)}
        value={activeId}
        variant="scrollable"
        scrollButtons={false}
      >
        {tabs.map((tab) => (
          <MuiTab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </MuiTabs>
      {tabs.map((tab) => {
        const { children, value, ...rest } = tab.panel ?? {};
        return (
          <TabPanel key={tab.value} tab={tab.label} value={tab.value} {...rest}>
            {tab.panel?.children}
          </TabPanel>
        );
      })}
    </Provider>
  );
};

Tabs.Panel = TabPanel;

export default Tabs;
