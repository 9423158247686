import React from 'react';

import { Routes } from 'pages/routes.constants';

export default {
  Component: React.lazy(
    () => import('./Login' /* webpackChunkName: "login", */),
  ),
  path: Routes.Login,
  authenticated: false,
};
