import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
  COGNITO_CLIENT_ID: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_REGION: string;
  FRONTEND_DOMAIN: string;
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',

  // error tracking
  SENTRY: {
    dsn: '',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en-GB',
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '',
  COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
  COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION ?? '',
  FRONTEND_DOMAIN: process.env.REACT_APP_FRONTEND_DOMAIN ?? '',
};

export default config;
