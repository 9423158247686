import {
  alpha,
  Components,
  formControlLabelClasses,
  Theme,
} from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  variants: [
    {
      props: { size: 'medium' },
      style: {
        '&& svg': {
          fontSize: '1.5rem',
        },
      },
    },
    {
      props: { color: 'primary' },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
        [`+ .${formControlLabelClasses.label}`]: {
          textTransform: 'none',
        },
      }),
    },
  ],
};
