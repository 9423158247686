import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button,
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
      marginBottom: theme.spacing(1),
      cursor: 'pointer',
    }),
  },
};
