import { Slide, useScrollTrigger } from '@mui/material';
import { isValidElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const HideOnScroll = ({ children }: Props) => {
  const trigger = useScrollTrigger();
  if (!isValidElement(children)) return null; // typescript guard because slideprops require children...
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
