import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES_EN from './translations/en.json';

const { DEFAULT_LOCALE } = config;

const locales = ['en-GB'];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  'en-GB': createIntl({ locale: 'en-GB', messages: MESSAGES_EN }, intlCache),
};

export { DEFAULT_LOCALE, intl, locales };
