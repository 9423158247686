import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';

import config from 'config';

import { runInDev, runInProd } from 'utils/env.utils';

import App from './App';

runInProd(() => Sentry.init(config.SENTRY));

runInDev(() => {
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
