import { combineReducers } from '@reduxjs/toolkit';

import intlReducer from './intl/intl.slice';

const reducers = {
  intl: intlReducer,
};

export const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
