import { Components, Theme } from '@mui/material';

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  defaultProps: {
    color: 'text.secondary',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      display: 'flex',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 3),
      },
    }),
  },
};
