import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { errorMessageFor } from 'utils/form.utils';

import { VALIDATIONS } from 'constants/validation.constants';
import { useResetPassword } from 'queries';

import { Routes } from 'pages/routes.constants';

import { Form, FormTextField } from 'components/@forms';

export type ResetPasswordFormValues = {
  newPassword: string;
  newPasswordRepeat: string;
  code: string;
  email: string;
};

const ForgotPasswordForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const { resetPassword, isLoading, error } = useResetPassword();

  const handleSubmit = (values: ResetPasswordFormValues) => {
    resetPassword(values, {
      onSuccess: () => {
        navigate(Routes.Login, { replace: true });
      },
    });
  };

  return (
    <>
      <Typography color="text.disabled" mb={3}>
        <FormattedMessage id="reset_password.description" />
      </Typography>
      <Form<ResetPasswordFormValues>
        onSubmit={handleSubmit}
        defaultValues={{
          newPassword: '',
          newPasswordRepeat: '',
          code: query.get('code') ?? '',
        }}
      >
        {({ watch }) => (
          <>
            <FormTextField
              name="code"
              inputMode="numeric"
              type="tel"
              label={
                <FormattedMessage id="form.label.reset_password_verification_code" />
              }
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'reset_password_verification_code',
                }),
              }}
            />
            <FormTextField
              name="email"
              inputMode="email"
              type="email"
              label={<FormattedMessage id="form.label.email" />}
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'email',
                }),
                pattern: {
                  value: VALIDATIONS.email,
                  message: intl.formatMessage({
                    id: 'form.validation.email.format',
                  }),
                },
              }}
            />
            <FormTextField
              label={<FormattedMessage id="form.label.new_password" />}
              id="newPassword"
              type="password"
              name="newPassword"
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'new_password',
                }),
              }}
              fullWidth
            />
            <FormTextField
              label={<FormattedMessage id="form.label.repeat_password" />}
              id="newPasswordRepeat"
              type="password"
              name="newPasswordRepeat"
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'repeat_password',
                }),
                validate: (value) =>
                  value === watch('newPassword') ||
                  errorMessageFor(intl, {
                    type: 'mismatch',
                    label: 'repeat_password',
                  }),
              }}
              fullWidth
            />
            <Box gap={2} display="flex" justifyContent="flex-end">
              <Button
                component={NavLink}
                to={Routes.Login}
                variant="text"
                color="inherit"
                tabIndex={1}
              >
                <FormattedMessage id="form.button.cancel" />
              </Button>
              <LoadingButton loading={isLoading} tabIndex={0} type="submit">
                <FormattedMessage id="form.button.reset_password" />
              </LoadingButton>
            </Box>
            {error && (
              <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
                <Typography variant="body2">
                  <FormattedMessage id="account.reset_password.error" />
                </Typography>
              </Box>
            )}
          </>
        )}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
