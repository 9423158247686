import { alertClasses, Components, Theme } from '@mui/material';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 8,
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: 400,
      },
    }),
    message: {
      whiteSpace: 'pre-wrap',
    },
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.snackbar.error.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.error.main,
      },
    }),
    standardSuccess: ({ theme }) => ({
      backgroundColor: theme.palette.snackbar.success.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.success.main,
      },
    }),
    standardWarning: ({ theme }) => ({
      backgroundColor: theme.palette.snackbar.warning.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.warning.main,
      },
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.snackbar.info.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.info.main,
      },
    }),
  },
};
