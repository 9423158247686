import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Advice, UpdateAdviceBody } from 'types/advice.types';

export const useUpdateAdvice = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isLoading } = useMutation(
    ({ id, body }: { body: UpdateAdviceBody; id: string }) =>
      apiService.patchAdvice(id, body),
    {
      onMutate: async (adviceBody) => {
        const adviceQueryKey = QueryKeys.advice.detail(adviceBody.id);
        await queryClient.cancelQueries(adviceQueryKey);
        const previousAdvice = queryClient.getQueryData<Advice>(adviceQueryKey);

        const newAdvice = {
          ...previousAdvice,
          ...adviceBody.body,
        };
        queryClient.setQueryData(adviceQueryKey, newAdvice);
        return { previousAdvice, newAdvice };
      },
      onError: (_err, adviceBody, context) => {
        queryClient.setQueryData(
          QueryKeys.advice.detail(adviceBody.id),
          context?.previousAdvice,
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.advice.overview(), {
          exact: false,
        });
      },
    },
  );
  return {
    updateAdvice: mutate,
    isError,
    isLoading,
  };
};
