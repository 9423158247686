import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Diagnostic, UpdateDiagnosticBody } from 'types/advice.types';

export const useUpdateDiagnostic = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isLoading } = useMutation(
    ({ id, body }: { body: UpdateDiagnosticBody; id: string }) =>
      apiService.patchDiagnostic(id, body),
    {
      onMutate: async (diagnosticBody) => {
        const diagnosticQueryKey = QueryKeys.diagnostic.detail(
          diagnosticBody.id,
        );
        await queryClient.cancelQueries(diagnosticQueryKey);
        const previousDiagnostic =
          queryClient.getQueryData<Diagnostic>(diagnosticQueryKey);

        const newDiagnostic = {
          ...previousDiagnostic,
          ...diagnosticBody.body,
        };
        queryClient.setQueryData(diagnosticQueryKey, newDiagnostic);
        return { previousDiagnostic, newDiagnostic };
      },
      onError: (_err, diagnosticBody, context) => {
        queryClient.setQueryData(
          QueryKeys.diagnostic.detail(diagnosticBody.id),
          context?.previousDiagnostic,
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.diagnostic.overview(), {
          exact: false,
        });
      },
    },
  );
  return {
    updateDiagnostic: mutate,
    isError,
    isLoading,
  };
};
