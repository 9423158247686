import { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  defaultProps: {
    variant: 'scrollable',
  },
  styleOverrides: {
    root: {
      minHeight: 24,
    },
    indicator: {
      display: 'none',
    },
  },
};
