import { Auth } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { CognitoError, CognitoUser } from 'types/auth.types';

interface Variables {
  user: CognitoUser;
  code: string;
}

export const useSubmitMfa = () => {
  const { mutate, isLoading, error } = useMutation<
    CognitoUserSession,
    CognitoError,
    Variables
  >(({ code, user }) => Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA'));
  return {
    submitMFA: mutate,
    isLoading,
    error,
  };
};
