import { Components, tabClasses, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: 18,
      fontWeight: 400,
      textTransform: 'none',
      padding: theme.spacing(0, 1),
      minHeight: 'initial',
      minWidth: 'initial',
      color: theme.palette.text.disabled,
      '&:first-of-type': {
        paddingLeft: 0,
      },
      [`&.${tabClasses.selected}`]: {
        color: theme.palette.text.secondary,
      },
    }),
  },
};
