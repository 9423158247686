import { useSnackbar as useNotistackSnackbar } from 'notistack';
import { useCallback } from 'react';

import { Alert, AlertProps } from 'components/@common';

import { DEFAULT_AUTO_HIDE } from './constants';

export const useSnackbar = () => {
  const { enqueueSnackbar } = useNotistackSnackbar();

  const add = useCallback(
    (props: AlertProps, duration: number = DEFAULT_AUTO_HIDE) => {
      enqueueSnackbar(<Alert {...props} />, {
        autoHideDuration: duration,
      });
    },
    [enqueueSnackbar],
  );

  const success = useCallback(
    (props: AlertProps, duration?: number) =>
      add({ ...props, severity: 'success' }, duration),
    [add],
  );

  const error = useCallback(
    (props: AlertProps, duration?: number) =>
      add({ ...props, severity: 'error' }, duration),
    [add],
  );

  const warning = useCallback(
    (props: AlertProps, duration?: number) =>
      add({ ...props, severity: 'warning' }, duration),
    [add],
  );

  return {
    add,
    error,
    warning,
    success,
  };
};
