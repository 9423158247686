import { Components, Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button,
      textUnderlineOffset: 4,
      textTransform: 'none',
      cursor: 'pointer',
    }),
  },
};
