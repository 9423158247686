import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { BIEmbed, BIEmbedType, BIEmbedUrl } from 'types/bi.types';

export const useBiEmbedUrl = (
  embed: BIEmbed,
  options?: UseCommonQueryOptions<
    BIEmbedUrl,
    ReturnType<typeof QueryKeys.bi.embedUrl>
  >,
) => {
  const params =
    embed?.type === BIEmbedType.Dashboard
      ? {
          dashboardId: embed?.dashboardId,
          type: BIEmbedType.Dashboard,
        }
      : {
          ...embed,
        };
  const { data, isLoading, isError } = useQuery(
    QueryKeys.bi.embedUrl(params),
    () => apiService.getBiEmbedUrl(params),
    {
      ...options,
    },
  );
  return {
    data,
    isLoading,
    isError,
  };
};
