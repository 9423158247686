import { Container, ContainerProps } from '@mui/material';

import { Navigation } from 'components/@common';

interface Props extends ContainerProps {
  children?: React.ReactNode;
}

const AppShell = ({ children, sx, ...rest }: Props) => {
  return (
    <>
      <Navigation />
      <Container
        maxWidth="xl"
        {...rest}
        sx={{
          py: 5,
          display: 'flex',
          flexDirection: 'column',
          flex: '1 auto',
          minHeight: 'calc(100vh - 64px)',
          ...sx,
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default AppShell;
