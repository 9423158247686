export enum BIEmbedType {
  Dashboard = 'DASHBOARD',
  Visual = 'VISUAL',
}

export type BIEmbed = {
  type: BIEmbedType;
  dashboardId: string;
  visualId?: string;
  sheetId?: string;
};

export type BIEmbedUrl = {
  embedUrl: string;
  type: BIEmbedType;
};

export type Dashboard = {
  embedUrl: string;
  type: BIEmbedType;
};
