import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      borderRadius: 4,
    },
    labelSmall: ({ theme }) => ({
      ...theme.typography.caption,
    }),
    filled: ({ theme }) => ({
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.secondary.light,
    }),
  },
};
