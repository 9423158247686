import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  useMediaQuery,
} from '@mui/material';

export interface Props extends Omit<DialogProps, 'title'> {
  children: React.ReactNode;
  title?: React.ReactNode;
  onClose?: (
    event: {},
    reacon: 'backdropClick' | 'escapeKeyDown' | 'iconClick',
  ) => void;
}

const Dialog = ({
  children,
  open,
  onClose = () => {},
  title,
  ...dialogProps
}: Props) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <MuiDialog
      keepMounted
      maxWidth="md"
      fullScreen={isSm}
      open={open}
      PaperProps={{ sx: { width: { xs: '100%', md: '60%' } } }}
      onClose={onClose}
      {...dialogProps}
    >
      {title && (
        <DialogTitle variant="button" position="relative">
          {title}
          <IconButton
            onClick={(e) => onClose(e, 'iconClick')}
            sx={{ ml: 'auto', color: 'text.disabled' }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      {children}
    </MuiDialog>
  );
};

// quite lame to just re-export but this is to keep everything consistent....
Dialog.Content = DialogContent;
Dialog.Actions = DialogActions;

export default Dialog;
