import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { useCreateMfaSetup, useCurrentUser } from 'queries';
import { CognitoError, CognitoErrors } from 'types/auth.types';

import { Form, FormTextField } from 'components/@forms';

import { LoginState } from '../types';
import { generateCognitoMfaQrUrl } from '../utils';

export type MFASetupValues = {
  code: string;
};

interface Props {
  onLoginStateChange: (state: LoginState) => void;
}

const MessageByErrorCode = ({ error }: { error: CognitoError | null }) => {
  if (!error) return null;
  switch (error.code) {
    case CognitoErrors.CodeMismatchException:
    case CognitoErrors.InvalidParameterException:
    case CognitoErrors.EnableSoftwareTokenMFAException: {
      return <FormattedMessage id="error.mfa" />;
    }
    case CognitoErrors.ExpiredCodeException: {
      return <FormattedMessage id="error.mfa.other" />;
    }
  }
  return <FormattedMessage id="error.mfa.other" />;
};

const MFASetupForm = ({ onLoginStateChange }: Props) => {
  const [qrCode, setQrCode] = useState('');
  const intl = useIntl();
  const { user, isLoading: userLoading } = useCurrentUser();
  const {
    setupMFA,
    verifyMFA,
    isLoading: settingUpMfa,
    verificationError,
    error,
  } = useCreateMfaSetup();
  const isLoading = userLoading || settingUpMfa;

  useEffect(() => {
    if (user) {
      setupMFA(
        { user },
        {
          onSuccess: (code) => {
            setQrCode(code);
          },
        },
      );
    }
  }, [user, setupMFA]);

  const handleSubmit = (values: MFASetupValues) => {
    if (!user) return;
    verifyMFA(
      { user, code: values.code },
      {
        onSuccess: () => {
          onLoginStateChange(LoginState.Success);
        },
      },
    );
  };

  return (
    <>
      <Typography whiteSpace="pre-wrap" color="text.disabled" mb={3}>
        <FormattedMessage
          id="form.mfa_setup.description"
          values={{
            code: (
              <Typography
                display="block"
                sx={{ wordBreak: 'break-all' }}
                component="span"
                color="text.secondary"
                variant="body2"
                fontWeight={400}
              >
                {qrCode}
              </Typography>
            ),
          }}
        />
      </Typography>
      <Box mb={3} textAlign="center">
        <QRCodeSVG
          value={generateCognitoMfaQrUrl(user?.username ?? '', qrCode)}
          size={220}
        />
      </Box>
      <Form<MFASetupValues>
        defaultValues={{
          code: '',
        }}
        onSubmit={handleSubmit}
      >
        <Typography whiteSpace="pre-wrap" color="text.disabled" mb={2}>
          <FormattedMessage id="form.mfa_setup.description_2" />
        </Typography>
        <FormTextField
          label={<FormattedMessage id="form.label.mfa_code" />}
          id="code"
          type="number"
          name="code"
          rules={{
            required: errorMessageFor(intl, {
              type: 'required',
              label: 'mfa_code',
            }),
          }}
          fullWidth
        />
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton loading={isLoading} type="submit">
            <FormattedMessage id="form.button.submit" />
          </LoadingButton>
        </Box>
      </Form>
      {(verificationError ?? error) && (
        <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
          <Typography variant="body2">
            <MessageByErrorCode error={verificationError ?? error} />
          </Typography>
        </Box>
      )}
    </>
  );
};

export default MFASetupForm;
