import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { ReactNode } from 'react';

import { DEFAULT_AUTO_HIDE } from './constants';

interface Props {
  children: ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={DEFAULT_AUTO_HIDE}
      preventDuplicate
      content={(key, message) => <div key={key}>{message}</div>}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
