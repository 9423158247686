import { useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Advice } from 'types/advice.types';
import { CollectionResponse } from 'types/response.types';

export const useAdvice = (
  id: string,
  options?: UseCommonQueryOptions<
    Advice,
    ReturnType<typeof QueryKeys.advice.detail>
  >,
) => {
  const queryClient = useQueryClient();
  const queryState = queryClient.getQueryState<CollectionResponse<Advice>>(
    QueryKeys.advice.overview(),
    {
      exact: false,
    },
  );

  const { data, isLoading, error } = useQuery(
    QueryKeys.advice.detail(id),
    () => apiService.getAdvice(id),
    {
      staleTime: StaleTime.FIVE_MIN,
      cacheTime: StaleTime.FIVE_MIN,
      enabled: !!id,

      initialData: () => {
        return queryState?.data?.data?.find((advice) => advice.id === id);
      },
      initialDataUpdatedAt: 0,
      ...options,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};
