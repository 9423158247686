import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

interface SelectOption {
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
}

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  SelectProps & {
    helperText?: React.ReactNode;
    options?: SelectOption[];
  };

const FormSelectField = ({
  rules,
  name,
  label,
  displayEmpty = true,
  helperText,
  native,
  placeholder,
  options = [],
  ...rest
}: Props) => {
  const { control } = useFormContext();
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const showNative = native ?? isSm;

  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => {
        return (
          <Box mb={2}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Select
              id={name}
              variant="filled"
              fullWidth
              {...field}
              inputRef={ref}
              error={!!error}
              displayEmpty={displayEmpty}
              value={value ?? ''}
              native={showNative}
              {...rest}
            >
              {placeholder &&
                (showNative ? (
                  <option disabled selected value="">
                    {placeholder}
                  </option>
                ) : (
                  <MenuItem sx={{ display: 'none' }} disabled selected value="">
                    {placeholder}
                  </MenuItem>
                ))}
              {options.map(({ value, label, disabled }) => {
                return showNative ? (
                  <option key={value} value={value} disabled={disabled}>
                    {label}
                  </option>
                ) : (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={disabled}
                    sx={{ fontSize: 14, fontWeight: 500 }}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
            {(error?.message ?? helperText) && (
              <FormHelperText>{error?.message ?? helperText}</FormHelperText>
            )}
          </Box>
        );
      }}
    />
  );
};

export default FormSelectField;
