import React from 'react';

import { Routes } from 'pages/routes.constants';

export default {
  Component: React.lazy(
    () => import('../Advices' /* webpackChunkName: "AdvicesDetail", */),
  ),
  path: Routes.AdvicesDetail,
  authenticated: true,
};

export const diagnosticDetailRedirect = {
  Component: React.lazy(
    () => import('../Advices' /* webpackChunkName: "AdvicesDetail", */),
  ),
  path: Routes.DiagnosticsDetail,
  authenticated: true,
};
