import { Components, Theme } from '@mui/material';

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    gutters: ({ theme }) => ({
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    }),
  },
};
