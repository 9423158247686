import { Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      gap: 8,
      padding: 0,
      ...theme.typography.button,
      textTransform: 'none',
      '& > svg': {
        fontSize: 24,
      },
    }),
  },
};
