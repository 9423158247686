import { useMutation } from '@tanstack/react-query';

import { downloadFileFromBinaryResponse } from 'utils/browser.utils';

import { apiService } from 'services';

const useCreateSiteExport = () => {
  const { mutate, isLoading, isError } = useMutation(
    ({ siteId }: { siteId: string }) => apiService.getSiteExport(siteId),
    {
      onSuccess: (response, body) => {
        downloadFileFromBinaryResponse(
          response,
          `export_${body.siteId}`,
          'xlsx',
        );
      },
    },
  );

  return {
    exportSiteInsights: mutate,
    isLoading,
    isError,
  };
};

export default useCreateSiteExport;
