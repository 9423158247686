import '@mui/lab/themeAugmentation';

import { createTheme, Shadows } from '@mui/material/styles';
/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  palette: {
    text: {
      primary: '#3D5469',
      secondary: '#111F2B',
      disabled: '#A4B3BF',
    },
    primary: {
      light: '#C6E6EB',
      main: '#008CA5',
    },
    snackbar: {
      error: {
        main: '#fdeded',
      },
      warning: {
        main: '#fff4e5',
      },
      success: {
        main: '#edf7ed',
      },
      info: {
        main: '#e5f6fd',
      },
    },
    secondary: {
      dark: '#abaeb0',
      main: '#F5F9FC',
      light: '#F0F5FA',
    },
    error: {
      light: '#FEE6E5',
      main: '#EF4D40',
    },
    background: {
      default: '#FAFBFC',
      paper: '#ffff',
      paperDark: '#F0F5FA',
    },
  },
  typography: {
    fontSize: 18,
    fontFamily: '"Buenos Aires", sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: '4.209rem',
      lineHeight: 1,
    },
    h2: {
      fontWeight: 400,
      fontSize: '3.157rem',
      lineHeight: 1.14,
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.369rem',
      lineHeight: 1.14,
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.777rem',
      lineHeight: 1.25,
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.338rem',
      lineHeight: 1.33,
    },
    h6: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.33,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.33,
      fontWeight: 300,
    },
    body2: {
      fontSize: '0.778rem',
      lineHeight: 1.71,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: '0.778rem',
      lineHeight: 1.71,
    },
    button: {
      fontWeight: 400,
      fontSize: '0.778rem',
      lineHeight: 1.71,
    },
    caption: {
      fontWeight: 300,
      lineHeight: 1.6,
      fontSize: '0.555rem',
    },
  },
  shadows: [
    'none',
    '0px 0px 20px rgba(0, 140, 165, 0.08)',
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    ...new Array(22).fill('none'),
  ] as Shadows,
});
