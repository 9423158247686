import { Auth } from '@aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { CognitoUser } from 'types/auth.types';

export const useCurrentUser = (
  config?: UseCommonQueryOptions<
    CognitoUser,
    ReturnType<typeof QueryKeys.account.currentUser>
  >,
) => {
  const {
    data: user,
    status,
    isLoading,
    refetch,
  } = useQuery(
    QueryKeys.account.currentUser(),
    () => {
      return Auth.currentAuthenticatedUser();
    },
    {
      cacheTime: StaleTime.FIVE_MIN,
      staleTime: StaleTime.FIVE_MIN,
      ...config,
    },
  );

  return {
    user,
    isLoading,
    status,
    isLoggedIn: !!user,
    refetch,
  };
};
