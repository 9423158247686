import type { IntlShape } from 'react-intl';

interface MessageForProps {
  type: 'required' | 'min_length' | 'mismatch' | 'max_length';
  label: string;
  [key: string]: string | number;
}

export const errorMessageFor = (
  intl: IntlShape,
  { type, label, ...rest }: MessageForProps,
) =>
  intl.formatMessage(
    {
      id: `form.validation.${type}`,
    },
    {
      label: intl.formatMessage({ id: `form.label.${label}` }),
      ...rest,
    },
  );
