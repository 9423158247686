import { LoginState } from './types';

export const COGNITO_CHALLENGE_STATE_MAP: Record<string, LoginState> = {
  NEW_PASSWORD_REQUIRED: LoginState.CompleteSignup,
  MFA_SETUP: LoginState.MFAStetup,
  SOFTWARE_TOKEN_MFA: LoginState.MFA,
};

export const generateCognitoMfaQrUrl = (username: string, code: string) => {
  return (
    'otpauth://totp/AWSCognito:' +
    username +
    '?secret=' +
    code +
    '&issuer=Conncert'
  );
};
