import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useCurrentUser } from 'queries';

import { Routes } from 'pages/routes.constants';

import { LoadingScreen } from 'components/@states';

interface Props {
  redirectTo?: string;
  enabled?: boolean;
  children: ReactNode;
}

const CheckAuth = ({
  children,
  enabled = true,
  redirectTo = Routes.Login,
}: Props) => {
  const { isLoggedIn, isLoading: fetchingCurrentUser } = useCurrentUser();

  const location = useLocation();
  const shouldRedirect = !isLoggedIn && enabled;

  if (fetchingCurrentUser) {
    return <LoadingScreen />;
  }
  if (shouldRedirect) {
    // should not be happening because of the useEffect hook
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }
  return <>{children}</>;
};

export default CheckAuth;
