/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */

import advices from './advices';
import advicesDetail, { diagnosticDetailRedirect } from './advices/detail';
import login from './login';
import passports from './passports';
import sitePassportsDetail from './passports/detail';
import resetPassword from './reset-password';

export default [
  login,
  passports,
  sitePassportsDetail,
  resetPassword,
  advices,
  advicesDetail,
  diagnosticDetailRedirect,
];
