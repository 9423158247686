import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { VALIDATIONS } from 'constants/validation.constants';
import { useCreateCompleteNewPassword, useCurrentUser } from 'queries';

import { Form, FormTextField } from 'components/@forms';

import { LoginState } from '../types';
import { COGNITO_CHALLENGE_STATE_MAP } from '../utils';

export type CompleteSignupFormValues = {
  givenName: string;
  familyName: string;
  phoneNumber: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const mapFormFieldsToRequestBody = (values: CompleteSignupFormValues) => {
  return {
    newPassword: values.newPassword,
    attributes: {
      given_name: values.givenName,
      family_name: values.familyName,
      phone_number: values.phoneNumber,
    },
  };
};

interface Props {
  onLoginStateChange: (state: LoginState) => void;
}

const CompleteSignupForm = ({ onLoginStateChange }: Props) => {
  const intl = useIntl();
  const { user } = useCurrentUser();

  const { completeNewPassword, isLoading, error } =
    useCreateCompleteNewPassword();

  const handleSubmit = (values: CompleteSignupFormValues) => {
    if (!user) return;
    completeNewPassword(
      { user, ...mapFormFieldsToRequestBody(values) },
      {
        onSuccess: (user) => {
          if (
            user.challengeName &&
            COGNITO_CHALLENGE_STATE_MAP[user.challengeName]
          ) {
            onLoginStateChange(COGNITO_CHALLENGE_STATE_MAP[user.challengeName]);
            return;
          }
          onLoginStateChange(LoginState.Success);
        },
      },
    );
  };

  return (
    <>
      <Typography color="text.disabled" mb={3}>
        <FormattedMessage id="form.complete_signup.description" />
      </Typography>
      <Form<CompleteSignupFormValues>
        defaultValues={{
          newPassword: '',
          newPasswordRepeat: '',
          givenName: user?.attributes?.given_name ?? '',
          familyName: user?.attributes?.family_name ?? '',
          phoneNumber: user?.attributes?.phone_number ?? '',
        }}
        onSubmit={handleSubmit}
      >
        {({ watch }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  label={<FormattedMessage id="form.label.firstname" />}
                  name="givenName"
                  rules={{
                    required: errorMessageFor(intl, {
                      type: 'required',
                      label: 'firstname',
                    }),
                  }}
                  type="text"
                  inputMode="text"
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  label={<FormattedMessage id="form.label.lastname" />}
                  rules={{
                    required: errorMessageFor(intl, {
                      type: 'required',
                      label: 'lastname',
                    }),
                  }}
                  name="familyName"
                  type="text"
                  inputMode="text"
                  autoComplete="family-name"
                />
              </Grid>
            </Grid>
            <FormTextField
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              inputMode="tel"
              autoComplete="tel"
              helperText={
                <FormattedMessage
                  id="form.helpertext.eg"
                  values={{ value: '+324 12 34 56 78' }}
                />
              }
              label={<FormattedMessage id="form.label.phone" />}
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'phone',
                }),
                pattern: {
                  value: VALIDATIONS.phone,
                  message: intl.formatMessage({
                    id: 'form.validation.phone.format',
                  }),
                },
              }}
            />
            <FormTextField
              label={<FormattedMessage id="form.label.new_password" />}
              id="newPassword"
              type="password"
              name="newPassword"
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'new_password',
                }),
              }}
              fullWidth
            />
            <FormTextField
              label={<FormattedMessage id="form.label.repeat_password" />}
              id="newPasswordRepeat"
              type="password"
              name="newPasswordRepeat"
              rules={{
                required: errorMessageFor(intl, {
                  type: 'required',
                  label: 'repeat_password',
                }),
                validate: (value) =>
                  value === watch('newPassword') ||
                  errorMessageFor(intl, {
                    type: 'mismatch',
                    label: 'repeat_password',
                  }),
              }}
              fullWidth
            />
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton loading={isLoading} type="submit">
                <FormattedMessage id="form.button.submit" />
              </LoadingButton>
            </Box>
            {error && (
              <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
                <Typography variant="body2">
                  <FormattedMessage id="error.complete_signup" />
                </Typography>
              </Box>
            )}
          </>
        )}
      </Form>
    </>
  );
};

export default CompleteSignupForm;
