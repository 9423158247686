import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { CheckAuth } from 'components/@accounts';
import RouteBoundary from 'components/@boundaries/RouteBoundary';
import { HomeRouteLayout } from 'components/@layouts';
import { LoadingScreen } from 'components/@states';

import Home from './home';
import routes from './routes';

const NotFound = React.lazy(
  () => import('./not-found' /* webpackChunkName: "not-found" */),
);

const Pages = () => {
  return (
    <BrowserRouter>
      {/* HACK!! to keep the iframe of dashboard mounted (because of load time) we define it without a route */}
      <Suspense fallback={<LoadingScreen />}>
        <HomeRouteLayout>
          <Home.Component />
        </HomeRouteLayout>
      </Suspense>
      <Routes>
        {routes.map(({ Component, path, authenticated }) => {
          return authenticated ? (
            <Route
              key={path}
              path={path}
              errorElement={<RouteBoundary />}
              element={
                <CheckAuth>
                  <Suspense fallback={<LoadingScreen />}>
                    <Component />
                  </Suspense>
                </CheckAuth>
              }
            />
          ) : (
            <Route
              key={path}
              path={path}
              errorElement={<RouteBoundary />}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Component />
                </Suspense>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
