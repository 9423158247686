import React from 'react';

import { Routes } from 'pages/routes.constants';

export default {
  Component: React.lazy(
    () => import('./Home' /* webpackChunkName: "home", webpackPreload: true */),
  ),
  path: Routes.Root,
  authenticated: true,
};
