import { Routes } from 'pages/routes.constants';

export const PAGES = [
  {
    label: 'nav.dashboard.label',
    path: Routes.Root,
    exact: true,
  },
  {
    label: 'nav.advice.label',
    path: Routes.Advices,
    exact: false,
  },
  {
    label: 'nav.passports.label',
    path: Routes.SitePassports,
    exact: false,
  },
];
