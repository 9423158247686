import { Auth } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';

import { CognitoError } from 'types/auth.types';

interface Variables {
  email: string;
  newPassword: string;
  newPasswordRepeat: string;
  code: string;
}

export const useResetPassword = () => {
  const {
    mutate: resetPassword,
    isLoading,
    error,
  } = useMutation<unknown, CognitoError, Variables>(
    ({ email, code, newPassword }) =>
      Auth.forgotPasswordSubmit(email, code, newPassword),
  );

  return {
    resetPassword,
    isLoading,
    error,
  };
};
