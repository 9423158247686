import { useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Diagnostic } from 'types/advice.types';
import { CollectionResponse } from 'types/response.types';

export const useDiagnostic = (
  id: string,
  options?: UseCommonQueryOptions<
    Diagnostic,
    ReturnType<typeof QueryKeys.diagnostic.detail>
  >,
) => {
  const queryClient = useQueryClient();
  const queryState = queryClient.getQueryState<CollectionResponse<Diagnostic>>(
    QueryKeys.diagnostic.overview(),
    {
      exact: false,
    },
  );

  const { data, isLoading, isError } = useQuery(
    QueryKeys.diagnostic.detail(id),
    () => apiService.getDiagnostic(id),
    {
      staleTime: StaleTime.FIVE_MIN,
      cacheTime: StaleTime.FIVE_MIN,
      enabled: !!id,
      initialData: () => {
        return queryState?.data?.data?.find(
          (diagnostic) => diagnostic.id === id,
        );
      },
      initialDataUpdatedAt: 0,
      ...options,
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};
