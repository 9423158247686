import { SiteDetailResponse } from 'types/site.types';

import RestApiService from '.';

export async function getSiteDetail(this: RestApiService, id: string) {
  const resp = await this.api
    .get<SiteDetailResponse>(`/sites/${id}`)
    .then((resp) => resp.data);
  return resp;
}

export async function getSiteExport(this: RestApiService, siteId: string) {
  const resp = await this.api.get<Blob>(`/sites/${siteId}/diagnostics`, {
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return resp;
}
