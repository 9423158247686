import { Auth } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';

import { CognitoError } from 'types/auth.types';

interface Variables {
  email: string;
}

export const useSendForgotPasswordMail = () => {
  const { mutate, isLoading, error } = useMutation<
    unknown,
    CognitoError,
    Variables
  >(({ email }) => Auth.forgotPassword(email));
  return {
    sendForgotPasswordMail: mutate,
    isLoading,
    error,
  };
};
