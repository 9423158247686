import { createContext } from 'react';

interface Context {
  activeId: string;
  handleChange: (id: string) => void;
  hasMounted: string[];
}

export const tabsContext = createContext<Context>({
  activeId: '',
  handleChange: () => {},
  hasMounted: [],
});
