import { AxiosInstance } from 'axios';

import { getUserSettings } from './account.api';
import {
  getAdvice,
  getAdvices,
  getDiagnostic,
  getDiagnostics,
  patchAdvice,
  patchDiagnostic,
} from './advice.api';
import { getBiEmbedUrl, getDashboards } from './bi.api';
import { getSiteDetail, getSiteExport } from './site.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  public getAdvices = getAdvices;
  public getAdvice = getAdvice;
  public patchAdvice = patchAdvice;
  public getDiagnostics = getDiagnostics;
  public getDiagnostic = getDiagnostic;
  public patchDiagnostic = patchDiagnostic;
  public getDashboards = getDashboards;
  public getBiEmbedUrl = getBiEmbedUrl;
  public getUserSettings = getUserSettings;
  public getSiteDetail = getSiteDetail;
  public getSiteExport = getSiteExport;
}

export default RestApiService;
