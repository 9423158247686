import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { errorMessageFor } from 'utils/form.utils';

import { VALIDATIONS } from 'constants/validation.constants';
import { useSendForgotPasswordMail } from 'queries';

import { Routes } from 'pages/routes.constants';

import { Form, FormTextField } from 'components/@forms';

import { LoginState } from '../types';

export type ForgotPasswordFormValues = {
  email: string;
};

interface Props {
  onLoginStateChange: (state: LoginState) => void;
}

const ForgotPasswordForm = ({ onLoginStateChange }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { sendForgotPasswordMail, isLoading, error } =
    useSendForgotPasswordMail();

  const handleSubmit = (values: ForgotPasswordFormValues) => {
    sendForgotPasswordMail(values, {
      onSuccess: () => {
        navigate(Routes.ResetPassword, { replace: true });
      },
    });
  };

  return (
    <>
      <Typography color="text.disabled" mb={3}>
        <FormattedMessage id="form.forgot_password.description" />
      </Typography>
      <Form<ForgotPasswordFormValues>
        onSubmit={handleSubmit}
        defaultValues={{
          email: '',
        }}
      >
        <FormTextField
          name="email"
          label={<FormattedMessage id="form.label.email" />}
          rules={{
            required: errorMessageFor(intl, {
              type: 'required',
              label: 'email',
            }),
            pattern: {
              value: VALIDATIONS.email,
              message: intl.formatMessage({
                id: 'form.validation.email.format',
              }),
            },
          }}
        />
        <Box gap={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => onLoginStateChange(LoginState.Login)}
            variant="text"
            color="inherit"
            tabIndex={1}
          >
            <FormattedMessage id="form.button.cancel" />
          </Button>
          <LoadingButton tabIndex={0} loading={isLoading} type="submit">
            <FormattedMessage id="form.button.send_reset_link" />
          </LoadingButton>
        </Box>
        {error && (
          <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
            <Typography variant="body2">
              <FormattedMessage id="account.reset_password.error" />
            </Typography>
          </Box>
        )}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
