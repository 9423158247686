import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  Drawer,
  IconButton,
  Link,
  Stack,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { PAGES } from './config';

const MobileMenu = () => {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleToggleNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav((prevState) => (!!prevState ? null : event.currentTarget));
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleToggleNavMenu}
        color="inherit"
      >
        {!anchorElNav ? <MenuIcon /> : <CloseIcon />}
      </IconButton>
      <Drawer
        sx={{
          zIndex: (theme) => theme.zIndex.appBar - 1,
          display: { xs: 'block', md: 'none' },
        }}
        onClose={handleCloseNavMenu}
        anchor="top"
        open={!!anchorElNav && !isMd}
      >
        <Toolbar />
        <Stack px={2} pt={2} spacing={2}>
          {PAGES.map((page) => {
            return (
              <Link
                end={page?.exact}
                onClick={handleCloseNavMenu}
                underline="hover"
                key={page.label}
                component={page?.path ? NavLink : 'a'}
                to={page?.path ?? '#'}
                sx={{
                  fontSize: 16,
                  '&.active': {
                    fontWeight: 700,
                  },
                }}
              >
                <FormattedMessage id={page.label} />
              </Link>
            );
          })}
        </Stack>
      </Drawer>
    </>
  );
};

export default MobileMenu;
