import {
  Advice,
  AdviceParams,
  Diagnostic,
  DiagnosticParams,
  UpdateAdviceBody,
  UpdateDiagnosticBody,
} from 'types/advice.types';
import { CollectionResponse } from 'types/response.types';

import RestApiService from '.';

export async function getAdvices(this: RestApiService, params?: AdviceParams) {
  const resp = await this.api.get<CollectionResponse<Advice>>('/advices', {
    params,
  });
  return resp.data;
}

export async function getAdvice(this: RestApiService, id: string) {
  const resp = await this.api.get<Advice>(`/advices/${id}`);
  return resp.data;
}

// For now the response/params are the same but we split the api calls because in the future they may diverge
export async function getDiagnostics(
  this: RestApiService,
  params?: DiagnosticParams,
) {
  const resp = await this.api.get<CollectionResponse<Diagnostic>>(
    '/diagnostics',
    {
      params,
    },
  );
  return resp.data;
}

export async function getDiagnostic(this: RestApiService, id: string) {
  const resp = await this.api.get<Diagnostic>(`/diagnostics/${id}`);
  return resp.data;
}

export async function patchAdvice(
  this: RestApiService,
  id: string,
  body: UpdateAdviceBody,
) {
  const resp = await this.api.patch<Advice>(`/advices/${id}`, body);
  return resp.data;
}

export async function patchDiagnostic(
  this: RestApiService,
  id: string,
  body: UpdateDiagnosticBody,
) {
  const resp = await this.api.patch<Diagnostic>(`/diagnostics/${id}`, body);
  return resp.data;
}
