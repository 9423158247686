import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { QueryKeys, useCurrentUser } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Dashboard } from 'types/bi.types';
import { CollectionResponse } from 'types/response.types';

import { Routes } from 'pages/routes.constants';

export const useDashboards = (
  options?: UseCommonQueryOptions<
    CollectionResponse<Dashboard>,
    ReturnType<typeof QueryKeys.dashboard.overview>
  >,
) => {
  const { isLoggedIn } = useCurrentUser();
  const location = useLocation();

  const { data, isLoading, isError } = useQuery(
    QueryKeys.dashboard.overview(),
    () => apiService.getDashboards(),
    {
      enabled: location.pathname === Routes.Root && isLoggedIn,
      ...options,
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};
