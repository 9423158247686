import { Components, inputClasses, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: 'inherit',
      fontSize: 12,
      marginLeft: 0,
      color: theme.palette.text.disabled,
      [`&.${inputClasses.disabled}`]: {
        color: theme.palette.text.disabled,
      },
    }),
  },
};
