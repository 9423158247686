import { loadingButtonClasses } from '@mui/lab';
import { Components, Theme } from '@mui/material';

export const MuiLoadingButton: Components<Theme>['MuiLoadingButton'] = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${loadingButtonClasses.loading}`]: {
        textDecoration: 'none',
      },
      [`& .MuiLoadingButton-loadingIndicator`]: {
        color: theme.palette.common.white,
      },
    }),
  },
};
