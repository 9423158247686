import { AppBar, Box, Container, Link, Toolbar } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';

import { useDeleteLogin } from 'queries';

import { Routes } from 'pages/routes.constants';

import { Icon } from 'components/@icons';
import { LoadingScreen } from 'components/@states';
import { HideOnScroll } from 'components/@utils';

import { PAGES } from './config';
import MobileMenu from './MobileMenu';
import NavigationSettings from './NavigationSettings';

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  const { signOut, isLoading } = useDeleteLogin();

  const handleLogout = () => {
    signOut(undefined, {
      onSuccess: () => {
        navigate(Routes.Login, { replace: true });
      },
    });
  };

  if (isLoading) {
    return <LoadingScreen labelId="state.logging_out.title" />;
  }

  return (
    <>
      <HideOnScroll>
        <AppBar elevation={2}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Link
                component={NavLink}
                to={Routes.Root}
                underline="none"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  fontSize: 125,
                  height: 32,
                  alignItems: 'center',
                  mr: 2,
                  ml: -2,
                }}
              >
                <Icon name="ConncertLogo" />
              </Link>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <MobileMenu />
              </Box>

              <Link
                component={NavLink}
                to={Routes.Root}
                underline="none"
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontSize: 125,
                  height: 32,
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Icon name="ConncertLogo" id="ConncertLogo" />
              </Link>
              <Box
                sx={{
                  gap: 2,
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {PAGES.map((page) => (
                  <Link
                    end={page?.exact}
                    component={page?.path ? NavLink : 'a'}
                    to={page?.path ?? '#'}
                    key={page.label}
                    sx={{
                      my: 2,
                      color: 'white',
                      display: 'block',
                      '&.active': {
                        fontWeight: 700,
                      },
                    }}
                  >
                    <FormattedMessage id={page.label} />
                  </Link>
                ))}
              </Box>
              <NavigationSettings onLogout={handleLogout} />
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      <Toolbar />
    </>
  );
};
export default ResponsiveAppBar;
