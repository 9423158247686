import { Container } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useCurrentUser } from 'queries';

import { Routes } from 'pages/routes.constants';

import { CheckAuth } from 'components/@accounts';
import { Navigation } from 'components/@common';

interface Props {
  children: React.ReactNode;
}

const HomeRouteLayout = ({ children }: Props) => {
  const location = useLocation();
  const isHomeRoute = location.pathname === Routes.Root;
  const { isLoggedIn } = useCurrentUser();

  return (
    <CheckAuth enabled={isHomeRoute}>
      {isHomeRoute && <Navigation />}
      <Container
        maxWidth="xl"
        sx={{
          py: 5,
          display: isHomeRoute ? 'flex' : 'none',
          flexDirection: 'column',
          flex: '1 auto',
          minHeight: 'calc(100vh - 64px)',
          '& *': {
            display: isHomeRoute ? undefined : 'none',
          },
        }}
      >
        {isLoggedIn ? children : null}
      </Container>
    </CheckAuth>
  );
};

export default HomeRouteLayout;
