import { Auth } from '@aws-amplify/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { CognitoError, CognitoUser } from 'types/auth.types';

interface Variables {
  email: string;
  password: string;
}

export const useCreateLogin = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation<
    CognitoUser,
    CognitoError,
    Variables
  >(({ email, password }) => Auth.signIn(email, password), {
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.account.currentUser(), data);
    },
  });
  return {
    createLogin: mutate,
    isLoading,
    error,
  };
};
