import {
  Box,
  FilledTextFieldProps,
  InputLabel,
  TextField,
} from '@mui/material';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<FilledTextFieldProps, 'variant' | 'name'>;

const FormTextField = ({ rules, name, label, helperText, ...rest }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => {
        return (
          <Box mb={2}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <TextField
              id={name}
              variant="filled"
              fullWidth
              {...field}
              inputRef={ref}
              error={!!error}
              helperText={error?.message ?? helperText}
              value={value ?? ''}
              {...rest}
            />
          </Box>
        );
      }}
    />
  );
};

export default FormTextField;
