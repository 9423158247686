import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { useCurrentUser, useSubmitMfa } from 'queries';
import { CognitoError, CognitoErrors } from 'types/auth.types';

import { Form, FormTextField } from 'components/@forms';

import { LoginState } from '../types';

export type MFASetupValues = {
  code: string;
};

interface Props {
  onLoginStateChange: (state: LoginState) => void;
}

const MessageByErrorCode = ({ error }: { error: CognitoError }) => {
  switch (error.code) {
    case CognitoErrors.CodeMismatchException:
    case CognitoErrors.InvalidParameterException:
    case CognitoErrors.EnableSoftwareTokenMFAException: {
      return <FormattedMessage id="error.mfa.expired" />;
    }
    case CognitoErrors.ExpiredCodeException: {
      return <FormattedMessage id="error.mfa.other" />;
    }
  }
  return <FormattedMessage id="error.mfa.other" />;
};

const MFAForm = ({ onLoginStateChange }: Props) => {
  const intl = useIntl();
  const { user, isLoading: userLoading } = useCurrentUser();

  const { submitMFA, isLoading: isVerifying, error } = useSubmitMfa();

  const isLoading = userLoading || isVerifying;

  const handleSubmit = (values: MFASetupValues) => {
    if (!user) return;
    submitMFA(
      { user, code: values.code },
      {
        onSuccess: () => {
          onLoginStateChange(LoginState.Success);
        },
      },
    );
  };

  return (
    <>
      <Form<MFASetupValues>
        defaultValues={{
          code: '',
        }}
        onSubmit={handleSubmit}
      >
        <FormTextField
          label={<FormattedMessage id="form.label.mfa_code" />}
          id="code"
          type="number"
          name="code"
          rules={{
            required: errorMessageFor(intl, {
              type: 'required',
              label: 'mfa_code',
            }),
          }}
          fullWidth
        />
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton loading={isLoading} type="submit">
            <FormattedMessage id="form.button.submit" />
          </LoadingButton>
        </Box>
        {error && (
          <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
            <Typography variant="body2">
              <MessageByErrorCode error={error} />
            </Typography>
          </Box>
        )}
      </Form>
    </>
  );
};

export default MFAForm;
