import * as Sentry from '@sentry/react';

import { FallbackProps } from './boundary.types';

// Since nothing is loaded at this point the fallback can only return basic HTML
// Do not attempt to translate this error or add styles, they are not loaded yet!
const Fallback = ({ error, componentStack }: FallbackProps) => {
  const message = error?.message;
  return (
    <>
      <h1>Whoopsie</h1>
      {message && <p>{message}</p>}
      <pre>{componentStack}</pre>
    </>
  );
};

interface Props {
  children: React.ReactNode;
}

// This is the everything else failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'root')}
    fallback={(props) => <Fallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
