export type CognitoUserAttributes = {
  email: string;
  given_name?: string;
  family_name?: string;
  phone_number?: string;
  email_verified: boolean;
  locale?: string;
  sub: string;
};

export type CognitoUser = {
  username: string;
  userDataKey: string;
  attributes: CognitoUserAttributes;
  client: {
    endpoint: string;
  };
  challengeName?: string;
  cognitoGroups: string[];
  keyPrefix: string[];
  signInUserSession: {
    accessToken: {
      jwtToken: string;
      payload: {
        auth_time: number;
        client_id: string;
        device_key: string;
        exp: number;
        iat: number;
        iss: string;
        jti: string;
        scope: string;
        sub: string;
        token_use: string;
        username: string;
      };
    };
  };
};

export type CognitoUserParams = {
  username: string;
  password: string;
  attributes: CognitoAttributeParams;
};

export type CognitoAttributeParams = Partial<{
  name: string;
  email: string;
  given_name: string;
  family_name: string;
  phone_number: string;
}>;

export enum CognitoErrors {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
  ResourceConflictException = 'ResourceConflictException',
  CodeMismatchException = 'CodeMismatchException',
  InvalidParameterException = 'InvalidParameterException',
  EnableSoftwareTokenMFAException = 'EnableSoftwareTokenMFAException',
  ExpiredCodeException = 'ExpiredCodeException',
}

export type CognitoError = {
  code: keyof typeof CognitoErrors;
  message: string;
  name: string;
};
