import React from 'react';

import { Routes } from 'pages/routes.constants';

export default {
  Component: React.lazy(
    () =>
      import('./Passports' /* webpackChunkName: "SitePassportsOverview", */),
  ),
  path: Routes.SitePassports,
  authenticated: true,
};
