import { Components, Theme } from '@mui/material';

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  defaultProps: {
    variant: 'rounded',
  },
  styleOverrides: {
    rounded: () => ({
      borderRadius: 8,
    }),
  },
};
