import { AxiosResponse } from 'axios';

export const downloadFileFromBinaryResponse = (
  response: AxiosResponse<Blob>,
  fileName?: string,
  extension?: string,
) => {
  let fileNameWithExtension = '';
  const disposition = response.request.getResponseHeader('Content-Disposition');
  const type = response.request.getResponseHeader('Content-Type');

  if (disposition && disposition.indexOf('attachment') !== -1) {
    // regular expression to get the filename from content disposition header (attachment; filename=test.csv)
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      fileNameWithExtension = matches[1].replace(/['"]/g, '').trim(); // remove quotes if any
    }
  } else {
    fileNameWithExtension = `${fileName}.${extension}`;
  }

  let blob = new Blob([response.data], { type });
  const tempLink = document.createElement('a');

  tempLink.href = window.URL.createObjectURL(blob);
  tempLink.download = fileNameWithExtension;

  document.body.appendChild(tempLink);
  tempLink.click();
  window.URL.revokeObjectURL(tempLink.href);
  document.body.removeChild(tempLink);
};
