import { AppBar, Container, Link, Toolbar } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Icon } from 'components/@icons';
import { HideOnScroll } from 'components/@utils';

const NavigationReduced = () => {
  return (
    <>
      <HideOnScroll>
        <AppBar elevation={2}>
          <Container maxWidth="xl">
            <Toolbar
              disableGutters
              sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
            >
              <Link
                component={NavLink}
                to={Routes.Root}
                underline="none"
                sx={{
                  display: 'flex',
                  fontSize: 125,
                  height: 32,
                  alignItems: 'center',

                  mr: 2,
                  ml: -2,
                }}
              >
                <Icon name="ConncertLogo" id="c1" />
              </Link>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  );
};
export default NavigationReduced;
