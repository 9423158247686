import { Components, filledInputClasses, Theme } from '@mui/material';

export const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
      background: theme.palette.secondary.light,
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 300,
      [`&.${filledInputClasses.focused}, &:hover`]: {
        backgroundColor: theme.palette.secondary.light,
      },
      [`&.${filledInputClasses.disabled}`]: {
        backgroundColor: theme.palette.text.disabled,
      },
      [`&.${filledInputClasses.error}`]: {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.error.main}`,
      },
    }),
    input: ({ theme }) => ({
      fontWeight: 300,
      padding: theme.spacing(1.5, 2),
      [`&.${filledInputClasses.disabled}`]: {
        color: theme.palette.text.disabled,
        WebkitTextFillColor: 'unset',
      },
      // overrides of browser prefill styles https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
      [` &:-webkit-autofill:focus`]: {
        boxShadow: `0 0 0px 500px ${theme.palette.secondary.light} inset`,
      },
      [`&:-webkit-autofill`]: {
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.light,
        boxShadow: ` 0 0 0px 500px ${theme.palette.secondary.light} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
      },
    }),
  },
  defaultProps: {
    disableUnderline: true,
  },
};
