import { Box, BoxProps, CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props extends BoxProps {
  labelId?: string;
}
const LoadingScreen = ({ labelId, ...rest }: Props) => {
  return (
    <Box
      minHeight="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      {...rest}
    >
      <CircularProgress size={24} aria-label="Loading" />
      {labelId && (
        <Typography mt={2}>
          <FormattedMessage id={labelId} />
        </Typography>
      )}
    </Box>
  );
};

export default LoadingScreen;
