import { UserSettingsResponse } from 'types/account.types';

import RestApiService from '.';

export async function getUserSettings(this: RestApiService) {
  const resp = await this.api
    .get<UserSettingsResponse>(`/settings`)
    .then((resp) => resp.data);
  return resp;
}
