import { Components, Theme } from '@mui/material';

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(0, 2, 2, 2),
      justifyContent: 'flex-start',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 3, 3, 3),
      },
    }),
  },
};
