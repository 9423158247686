import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLocale } from 'store/intl/intl.selectors';

import { intl } from './config';

interface Props {
  children: React.ReactNode;
}

const IntlSelectorProvider = ({ children }: Props) => {
  const locale = useSelector(getLocale);

  return (
    <RawIntlProvider key={locale} value={intl[locale]}>
      {children}
    </RawIntlProvider>
  );
};

export default IntlSelectorProvider;
