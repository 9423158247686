import React from 'react';

import { Routes } from 'pages/routes.constants';

export default {
  Component: React.lazy(
    () => import('./Advices' /* webpackChunkName: "Advices" */),
  ),
  path: Routes.Advices,
  authenticated: true,
};
