export enum Routes {
  Root = '/',
  Login = '/login',
  SitePassports = '/sites',
  SitePassportDetail = '/sites/:id',
  RedirectNotAuthorized = '/login',
  ResetPassword = '/password-reset',
  Advices = '/advices',
  AdvicesDetail = 'advices/:id',
  DiagnosticsDetail = 'diagnostics/:id',
}
