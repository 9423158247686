import { BIEmbed, BIEmbedUrl, Dashboard } from 'types/bi.types';
import { CollectionResponse } from 'types/response.types';

import RestApiService from '.';

export async function getDashboards(this: RestApiService) {
  const resp = await this.api.get<CollectionResponse<Dashboard>>('/dashboards');
  return resp.data;
}

export async function getBiEmbedUrl(this: RestApiService, params: BIEmbed) {
  const resp = await this.api
    .get<BIEmbedUrl>(`/bi-embed`, {
      params,
    })
    .then((resp) => resp.data);
  return resp;
}
