import { Auth } from '@aws-amplify/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { CognitoError, CognitoUser } from 'types/auth.types';

interface Variables {
  newPassword: string;
  user: CognitoUser;
  attributes: {
    phone_number: string;
    given_name: string;
    family_name: string;
  };
}

export const useCreateCompleteNewPassword = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation<
    CognitoUser,
    CognitoError,
    Variables
  >(
    ({ user, newPassword, attributes }) =>
      Auth.completeNewPassword(user, newPassword, attributes),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(QueryKeys.account.currentUser(), data);
      },
    },
  );
  return {
    completeNewPassword: mutate,
    isLoading,
    error,
  };
};
