import { Components, Theme } from '@mui/material';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(0, 2, 2, 2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 3, 3, 3),
      },
    }),
  },
};
