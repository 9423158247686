import { Auth } from '@aws-amplify/auth';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import config from 'config/env';

import { isDev } from 'utils/env.utils';

import IntlSelectorProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import { SnackbarProvider } from 'services/snackbar';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';
import { LoadingScreen } from 'components/@states';

Auth.configure({
  region: config.COGNITO_REGION,
  userPoolId: config.COGNITO_USER_POOL_ID,
  userPoolWebClientId: config.COGNITO_CLIENT_ID,
});

const App = () => {
  return (
    <RootBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ReduxProvider store={storeConfig.store}>
            <PersistGate
              loading={<LoadingScreen />}
              persistor={storeConfig.persistor}
              onBeforeLift={storeConfig.initApp}
            >
              <IntlSelectorProvider>
                <HelmetProvider>
                  <SnackbarProvider>
                    <Pages />
                  </SnackbarProvider>
                </HelmetProvider>
              </IntlSelectorProvider>
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
        {isDev() && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </QueryClientProvider>
    </RootBoundary>
  );
};

export default App;
