import { Settings as SettingsIcon } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  onLogout: () => void;
}

const NavigationSettings = ({ onLogout }: Props) => {
  const [anchorElSettings, setAnchorElSettings] = useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElSettings(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton
        sx={{ fontWeight: 400 }}
        color="inherit"
        onClick={handleOpenUserMenu}
      >
        <SettingsIcon />
        <Box component="span" display={{ xs: 'none', md: 'inline' }}>
          <FormattedMessage id="nav.settings.label" />
        </Box>
      </IconButton>

      <Menu
        elevation={2}
        sx={{ mt: 4 }}
        anchorEl={anchorElSettings}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorElSettings}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={onLogout}>
          <Typography
            color="text.primary"
            textTransform="none"
            variant="button"
            textAlign="center"
          >
            <FormattedMessage id="nav.logout.label" />
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NavigationSettings;
