import { alpha, Components, Theme, tooltipClasses } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    placement: 'bottom-end',
  },
  styleOverrides: {
    tooltip: ({ theme }) => ({
      fontSize: '0.778rem',
      backgroundColor: alpha(theme.palette.text.secondary, 0.7),
      fontWeight: 300,
      borderRadius: 8,
      boxShadow: '0px 0px 20px rgba(0, 140, 165, 0.08)',
    }),
    popper: ({ theme }) => ({
      [`&&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: theme.spacing(1),
      },
    }),
  },
};
