import { Auth } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { CognitoError, CognitoUser } from 'types/auth.types';

interface Variables {
  user: CognitoUser;
}

interface VerifyVariables {
  user: CognitoUser;
  code: string;
}

export const useCreateMfaSetup = () => {
  const { mutate, isLoading, error } = useMutation<
    string,
    CognitoError,
    Variables
  >(({ user }) => Auth.setupTOTP(user));

  const {
    mutate: verifyMFA,
    isLoading: isVerifying,
    error: verificationError,
  } = useMutation<CognitoUserSession, CognitoError, VerifyVariables>(
    ({ code, user }) => Auth.verifyTotpToken(user, code),
    {
      onSuccess: (_, { user }) => {
        Auth.setPreferredMFA(user, 'TOTP');
      },
    },
  );

  return {
    setupMFA: mutate,
    verifyMFA,
    isVerifying,
    verificationError,
    isLoading,
    error,
  };
};
