import { ComponentProps } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import { Checkbox } from 'components/@common';

type CheckboxProps = ComponentProps<typeof Checkbox>;

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<CheckboxProps, 'name'>;

const FormCheckbox = ({ rules, name, sx, ...rest }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <Checkbox
            {...field}
            sx={{
              mb: 2,
              ...sx,
            }}
            checked={field.value}
            inputRef={ref}
            error={!!error}
            helperText={error?.message}
            fullWidth
            {...rest}
          />
        );
      }}
    />
  );
};

export default FormCheckbox;
