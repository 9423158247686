import { Components, Theme } from '@mui/material';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      height: '100%',
      backgroundColor: theme.palette.background.default,
    }),
  },
};
