import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';

interface Props extends Omit<BoxProps, 'title'> {
  title: React.ReactNode;
  icon?: React.ReactNode;
  badges?: React.ReactNode[];
  actions?: React.ReactNode[];
}

const PageTitle = ({ title, actions, icon, badges, ...rest }: Props) => {
  return (
    <Box
      mb={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Box
        color="text.secondary"
        display="flex"
        gap={1}
        alignItems="flex-start"
      >
        {icon && <Box component="span">{icon}</Box>}
        <Box>
          <Typography color="text.secondary" variant="h4" component="h1">
            {title}
          </Typography>
          {badges && <Box mt={1}>{badges}</Box>}
        </Box>
      </Box>

      {actions && (
        <Box display="flex" gap={2}>
          {actions}
        </Box>
      )}
    </Box>
  );
};

export default PageTitle;
