import { DevTool } from '@hookform/devtools';
import { ReactNode, useEffect } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

import { SubmitHandler } from '../types';

interface Props<FormData extends FieldValues> {
  onSubmit: SubmitHandler<FormData>;
  defaultValues?: DefaultValues<FormData>;
  children: ReactNode | ((methods: UseFormReturn<FormData>) => ReactNode);
  enableDevTools?: boolean;
}

const Form = <FormData extends FieldValues>({
  defaultValues,
  onSubmit,
  children,
  enableDevTools,
}: Props<FormData>) => {
  const methods = useForm<FormData>({ defaultValues });
  const reset = methods.reset;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data, event) =>
          event ? onSubmit(data, event, methods) : undefined,
        )}
      >
        {children instanceof Function ? children(methods) : children}
      </form>
      {enableDevTools && <DevTool control={methods.control} />}
    </FormProvider>
  );
};

export default Form;
