import { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      body1: 'p',
      body2: 'p',
      overline: 'p',
    },
  },
  styleOverrides: {
    gutterBottom: {
      marginBottom: 8,
    },
  },
};
