import { buttonClasses, Components, darken, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
  },
  styleOverrides: {
    root: () => ({
      textTransform: 'none',
    }),
    contained: ({ theme }) => ({
      boxShadow: 'none',
      padding: theme.spacing(1),
      minHeight: 40,
      [`&:hover, &.${buttonClasses.focusVisible}`]: {
        boxShadow: 'none',
      },
    }),
    containedSecondary: ({ theme }) => ({
      color: theme.palette.primary.main,
      [`&:hover, &.${buttonClasses.focusVisible}`]: {
        backgroundColor: darken(theme.palette.secondary.main, 0.05),
      },
    }),
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      [`&:hover, &.${buttonClasses.focusVisible}`]: {
        backgroundColor: darken(theme.palette.primary.main, 0.2),
      },
    }),
    text: ({ theme }) => ({
      ...theme.typography.button,
      padding: 0,
      textTransform: 'none',
      minWidth: 'initial',
      [`&:hover`]: {
        backgroundColor: 'transparent',
      },
      [`&.${buttonClasses.focusVisible}`]: {
        outline: 1,
      },
    }),
  },
};
