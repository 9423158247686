import { LoadingButton } from '@mui/lab';
import { Box, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { VALIDATIONS } from 'constants/validation.constants';
import { useCreateLogin } from 'queries';
import { CognitoError, CognitoErrors } from 'types/auth.types';

import { Form, FormTextField } from 'components/@forms';

import { LoginState } from '../types';
import { COGNITO_CHALLENGE_STATE_MAP } from '../utils';

export type LoginFormValues = {
  email: string;
  password: string;
};

const MessageByErrorCode = ({ error }: { error: CognitoError }) => {
  switch (error.code) {
    case CognitoErrors.NotAuthorizedException:
    case CognitoErrors.UserNotFoundException:
      return <FormattedMessage id="error.login" />;
  }
  return <FormattedMessage id="error.login.other" />;
};

interface Props {
  onLoginStateChange: (state: LoginState) => void;
}

const LoginForm = ({ onLoginStateChange }: Props) => {
  const intl = useIntl();

  const { createLogin, isLoading, error } = useCreateLogin();

  const handleLogin = (values: LoginFormValues) => {
    createLogin(values, {
      onSuccess: (user) => {
        if (
          user.challengeName &&
          COGNITO_CHALLENGE_STATE_MAP[user.challengeName]
        ) {
          onLoginStateChange(COGNITO_CHALLENGE_STATE_MAP[user.challengeName]);
          return;
        }
        onLoginStateChange(LoginState.Success);
      },
    });
  };

  const handleForgotPasswordClick = () => {
    onLoginStateChange(LoginState.ForgotPassword);
  };

  return (
    <Form<LoginFormValues>
      defaultValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleLogin}
    >
      <FormTextField
        name="email"
        label={<FormattedMessage id="form.label.email" />}
        rules={{
          required: errorMessageFor(intl, {
            type: 'required',
            label: 'email',
          }),
          pattern: {
            value: VALIDATIONS.email,
            message: intl.formatMessage({
              id: 'form.validation.email.format',
            }),
          },
        }}
      />
      <FormTextField
        label={<FormattedMessage id="form.label.password" />}
        id="password"
        type="password"
        name="password"
        rules={{
          required: errorMessageFor(intl, {
            type: 'required',
            label: 'password',
          }),
        }}
        helperText={
          <Link onClick={handleForgotPasswordClick}>
            <FormattedMessage id="form.label.forgot_password" />
          </Link>
        }
        fullWidth
      />
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton loading={isLoading} type="submit">
          <FormattedMessage id="form.button.login" />
        </LoadingButton>
      </Box>
      {error && (
        <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
          <Typography variant="body2">
            <MessageByErrorCode error={error} />
          </Typography>
        </Box>
      )}
    </Form>
  );
};

export default LoginForm;
