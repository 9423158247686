import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props extends Omit<FormControlLabelProps, 'control'> {
  error?: boolean;
  helperText?: ReactNode;
  fullWidth?: boolean;
}

const Checkbox = ({ error, helperText, fullWidth, sx, ...rest }: Props) => {
  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      <FormControlLabel
        componentsProps={{ typography: { variant: 'button' } }}
        control={<MuiCheckbox />}
        {...rest}
      />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Checkbox;
